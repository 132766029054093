import React from "react";
import Navbar from "./components/navbar/navbar";
import Background from "./components/background/background";
import Footer from "./components/footer/footer";

function App() {
  console.clear();

  return (
    <>
      <Navbar/>
      <Background/>
      <Footer/>
    </>
  );
}
export default App;
