import React, { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme)=>({
    link:{
        textDecoration:"none",
        color: "black",
        fontSize: "20px",
        width:"200px",
    },
    icon:{
        color: "black",
        marginLeft: "30%",
        marginRight: "5%"
    },
    container: {
      marginLeft: theme.spacing(2)
    }

}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <Container className={classes.container}>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List  style={{width: "200px"}}>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <a href="#top" className={classes.link}>Home</a>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <a href="#about" className={classes.link}>About</a>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <a href="#roadmap" className={classes.link}>Roadmap</a>
            </ListItemText>
          </ListItem>
          <Divider/>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <a href="#contact" className={classes.link}>Contact</a>
            </ListItemText>
          </ListItem>
          <Divider/>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </Container>
  );
}
export default DrawerComponent;
