import React from 'react';
import './content.css';
import CloudAnimation from './cloud/cloud_animation';
import RobotAnimation from './robot/robot_animation';
import roadmap from './../../assets/roadmap/roadmap_transparent.png';
/** animation bound to scrolling*/
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Piechartjs from './chart/piechartjs';
import { FaInstagram, FaMailBulk, FaTwitter} from 'react-icons/fa';

gsap.registerPlugin(ScrollTrigger);

export default function Content() {

  return (
      <>
          {/*All the page content goes in here*/}
          <div className="parallax-foreground">
              <div className="content_container">
                  <div className="section_1">
                          <CloudAnimation />
                  </div>
                  <div className="section_2">
                        <RobotAnimation />
                        <div>
                            <h3>
                                That's why we build, test and launch custom trading strategies
                                for our clients
                            </h3>
                        </div>
                  </div>
                  <div className="section_3">
                      <div className="pie_section">
                          <span>
                              On top we also offer custom portfolio management and hedge
                              algorithms for instituions & firms
                          </span>
                      </div>
                  </div>
                  <div className='chart-container'>
                    <Piechartjs/>
                  </div>
                  <div className="section_4" id="about">
                      <span>
                          <h1>The Team</h1>
                      </span>
                        <div className="team-container" id='flex-container'>
                            <div className="profile-card">
                                <div className="img">
                                    <img
                                        className="profilepic"
                                        src={require("../../assets/DSC09877.jpg")}
                                        alt="Gabriel Chiochetti"
                                    ></img>
                                </div>
                                <div className="caption">
                                    <h3>Gabriel Chiochetti</h3>
                                    <p>Head of Strategy</p>
                                    <br></br>
                                    <div className="social-links">
                                        <a
                                            rel="noreferrer"
                                            href="https://www.instagram.com/authismus_addiction"
                                            target="_blank"
                                        >
                                            <FaInstagram />
                                        </a>
                                        <a href="mailto:gabriel.chiochetti@gmail.com">
                                            <FaMailBulk />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-card">
                                <div className="img">
                                    <img
                                        className="profilepic"
                                        src={require("../../assets/Profil_lucas_2.jpg")}
                                        alt="Lucas Peretto"
                                    ></img>
                                </div>
                                <div className="caption">
                                    <h3>Lucas Peretto</h3>
                                    <p>Full Stack Developer</p>
                                    <br></br>
                                    <div className="social-links">
                                        <a
                                            rel="noreferrer"
                                            href="https://www.instagram.com/l_a_u_s_e_r"
                                            target="_blank"
                                        >
                                            <FaInstagram />
                                        </a>
                                        <a href="mailto: lucas.tiers.it@gmail.com">
                                            <FaMailBulk />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-card">
                                <div className="img">
                                    <img
                                        className="profilepic"
                                        src={require("../../assets/profil_filippo_5.jpg")}
                                        alt="Filippo Costanzi"
                                    ></img>
                                </div>
                                <div className="caption">
                                    <h3>Filippo Costanzi</h3>
                                    <p>Front End Developer</p>
                                    <br></br>
                                    <div className="social-links">
                                        <a
                                            rel="noreferrer"
                                            href="https://www.instagram.com/filippo_costanzi"
                                            target="_blank"
                                        >
                                            <FaInstagram />
                                        </a>
                                        <a
                                            rel="noreferrer"
                                            href="mailto: business.costanzi@gmail.com"
                                            target="_blank"
                                        >
                                            <FaMailBulk />
                                        </a>
                                        <a
                                            rel="noreferrer"
                                            href="https://www.twitter.com/fil_costanzi"
                                            target="_blank"
                                        >
                                            <FaTwitter />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
                  <div className="section_5" id='roadmap'>
                      <span>
                        <p id='placeholder'></p>
                        <h1>Our journey so far</h1>
                      </span>
                      <div className="roadmap-container">
                        <img src={roadmap} className='roadmap-image' alt='roadmap'/>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}