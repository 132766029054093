import { useEffect } from "react";
import { Parallax } from "react-parallax";
import Content from "../content/content";
import { useMediaQuery } from 'react-responsive';

import './background.css';

export default function Background() {
    /** responsive query */
    const isDesktop = useMediaQuery({
        query: '(min-width: 800px)'
    })

     /**moving background animation*/
    useEffect(() => {
        window.addEventListener("resize", handleResize);

        function handleResize() {
        var canvas = document.getElementById("background-canvas"),
            ctx = canvas.getContext("2d");

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        var stars = [], // Array that contains the stars
            FPS = 100, // Frames per second
            x = 50, // Number of stars
            mouse = {
            x: 0,
            y: 0,
            }; // mouse location
        // minimize Number of stars on mobile view
        if(!isDesktop) {
            x = 15;
        }

        // Push stars to array
        for (var i = 0; i < x; i++) {
            stars.push({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            radius: Math.random() * 1 + 1,
            vx: Math.floor(Math.random() * 50) - 25,
            vy: Math.floor(Math.random() * 50) - 25,
            });
        }

        // Draw the scene

        function draw() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            ctx.globalCompositeOperation = "lighter";

            for (var i = 0, x = stars.length; i < x; i++) {
            var s = stars[i];

            ctx.fillStyle = "#fff";
            ctx.beginPath();
            ctx.arc(s.x, s.y, s.radius * 1.5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.fillStyle = "black";
            ctx.stroke();
            }

            ctx.beginPath();
            for (var i = 0, x = stars.length; i < x; i++) { // eslint-disable-line no-redeclare
            var starI = stars[i];
            ctx.moveTo(starI.x, starI.y);
            if (distance(mouse, starI) < 150) ctx.lineTo(mouse.x, mouse.y);
            for (var j = 0, x = stars.length; j < x; j++) { // eslint-disable-line no-redeclare
                var starII = stars[j];
                if (distance(starI, starII) < 150) {
                //ctx.globalAlpha = (1 / 150 * distance(starI, starII).toFixed(1));
                ctx.lineTo(starII.x, starII.y);
                }
            }
            }
            ctx.lineWidth = 0.12;
            ctx.strokeStyle = "white";
            ctx.stroke();
        }

        function distance(point1, point2) {
            var xs = 0;
            var ys = 0;

            xs = point2.x - point1.x;
            xs = xs * xs;

            ys = point2.y - point1.y;
            ys = ys * ys;

            return Math.sqrt(xs + ys);
        }

        // Update star locations

        function update() {
            for (var i = 0, x = stars.length; i < x; i++) {
            var s = stars[i];

            s.x += s.vx / FPS;
            s.y += s.vy / FPS;

            if (s.x < 0 || s.x > canvas.width) s.vx = -s.vx;
            if (s.y < 0 || s.y > canvas.height) s.vy = -s.vy;
            }
        }

        canvas.addEventListener("mousemove", function(e) {
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        });

        // Update and draw

        function tick() {
            draw();
            update();
            requestAnimationFrame(tick);
        }

        tick();
        }

        handleResize();
    }, []); // eslint-disable-line

    return(
        <>
            <Parallax>
                <canvas id="background-canvas"/>
                <div id="title_top">
                    <div className="title_top_flex_container">
                        <h2 class="main-title">Trading</h2>
                        <h2 class="main-title">meets</h2>
                        <h2 class="main-title">automation</h2>
                        <h3 id="subtitle">Innovating automation in the financial markets</h3>
                    </div>
                </div>
                <Content/>
            </Parallax>
        </>
    );

}