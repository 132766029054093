import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import DrawerComponent from "./drawer";
import { Grid } from "@material-ui/core";
import banner_logo from './../../assets/banner_logo.png'

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    color: "black",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontSize: "20px",
    marginLeft: theme.spacing(14),
    "&:hover": {
      textDecoration: "underline",
    },
    
  },
  Toolbar: {
    maxWidth: "1080px",
  },

}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar style={{backgroundColor: "rgba(234, 234, 234, 0.98)", 
      position: "fixed",
      }}>
      <Toolbar style={{width:"100%", minWidth:"300px", display:"flex", justifyContent: "center", padding: "0px"}}>
        <Grid style={{width:"100%", maxWidth:"1080px" , display: "flex", justifyContent:"space-between", alignItems: "center"}}>
          <Grid item="xs" >
            <img src={banner_logo} alt="banner_logo" style={{ height:"56px"}}></img>
          </Grid>
          {isMobile ? (
            <Grid item="xs">
              <DrawerComponent/>
            </Grid>
          ) : (
            <div className={classes.navlinks}>
              <Grid item="sm">
                <a href="#top" className={classes.link}>
                  Home
                </a>
              </Grid>

              <Grid item="sm">
                <a href="#about"  className={classes.link}>
                  About
                </a>
              </Grid>

              <Grid item="sm">
                <a href="#roadmap"  className={classes.link}>
                  Roadmap
                </a>
              </Grid>

              <Grid item="sm">
                <a href="#contact"  className={classes.link}>
                  Contact
                </a>
              </Grid>
            </div>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
