import React from 'react';
import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function CloudAnimation() {  
    function importAll(r) {
        return r.keys().map(r);
    }
    const imagesCLOUD = importAll(
        require.context("./../../../assets/cloud_animation_images", false, /\.(png|jpeg|svg)$/)
    );

    useEffect(() => {
        // console.clear();

        const canvas = document.getElementById("cloud-canvas");
        // var canvas = canvas_ref;
        const context = canvas.getContext("2d");

        
        canvas.width = 1920;
        canvas.height = 1080;
        
        const frameCount = 130;
    
        const currentFrame = (index) => imagesCLOUD[index];

        const images = [];
        const airpods = {
            frame: 0,
        };

        for (let i = 0; i < frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            images.push(img);
        }

        gsap.to(airpods, {
            frame: frameCount - 1,
            snap: "frame",
            
            scrollTrigger: {
                trigger: "#cloud-canvas",
                scrub: 2,
                start: 'top+=50px top+=100px',
                end: '+=2000px',
                pin: true,
                pinSpacing: true,
                anticipatePin: 1,
                
            },
            onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
        });

        images[0].onload = render;


        context.drawImage(
            images[airpods.frame],
            0,
            0,
            images[airpods.frame].width,
            images[airpods.frame].height,
        );

        function render() {
            /*
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(images[airpods.frame], 0, 0);
            */
           var hRatio = canvas.width / images[airpods.frame].width;
           var vRatio = canvas.height / images[airpods.frame].height;
           var ratio = Math.min(hRatio, vRatio);
           var centerShift_x = (canvas.width - images[airpods.frame].width * ratio) / 2;
           var centerShift_y = (canvas.height - images[airpods.frame].height * ratio) / 2;
           context.clearRect(0, 0, canvas.width, canvas.height);
           context.drawImage(
               images[airpods.frame],
               0,
               0,
               images[airpods.frame].width,
               images[airpods.frame].height,
               centerShift_x,
               centerShift_y,
               images[airpods.frame].width * ratio,
               images[airpods.frame].height * ratio
           );
        }
    }, []);// eslint-disable-line
    
    return (
        <>
            <div className='canvas-div'>
                <div className="animation-desc-container">
                    <h2 id="animation-desc">
                        The financial market often looks like a storm
                    </h2>
                </div>
                <canvas id="cloud-canvas" />
            </div>
        </>
    );
}

