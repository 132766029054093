import './footer.css'
import React from 'react';

import {FaGithub, FaInstagram, FaLinkedin, FaMailBulk } from 'react-icons/fa';

export default function Footer () {
    return (
        <div class="footer">
             <div class="content_footer" id="contact">
                Contact us &#8594;<a href="mailto:team.2tm@protonmail.com" id='send_email'> Send an email</a>
             </div>
             <div class="icons">
                <a rel="noreferrer" href="https://www.instagram.com/piccolo_metrics/" target="_blank"><FaInstagram id='insta_icon'/></a>
                <a rel="noreferrer" href="https://de.linkedin.com/" target="_blank"><FaLinkedin/></a>
                <a rel="noreferrer" href="https://github.com/costanzifilippo" target="_blank"><FaGithub/></a>
                <a rel="noreferrer" href="mailto:team.2tm@protonmail.com"><FaMailBulk/></a>
             </div>
             <div class="copyright">
                ©  2022 
                &nbsp;
                Web by | 2TM piccolo | All rights reserved
             </div>
        </div>
    );
}